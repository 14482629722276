const routes = {
  path: '/admin/customer',
  name: 'AdminClientMain',
  meta: {
    layout: 'AdmLayout',
    title: '고객 관리',
    dep: 2
  },
  component: () => import(/* webpackChunkName: "adminclient" */ '@/views/admin/customer/Main.vue'),
  children: [

    {
      path: 'list',
      alias: '',
      name: 'AdminCustomerList',
      component: () => import(/* webpackChunkName: "adminclient" */ '@/views/admin/customer/List.vue'),
      meta: {
        btnOn: 1
      }
    },
    {
      path: 'write',
      alias: '',
      name: 'AdminCustomerWrite',
      component: () => import(/* webpackChunkName: "adminclient" */ '@/views/admin/customer/Write.vue'),
      meta: {
        btnOn: 1
      }
    }
  ]
}

export default routes
