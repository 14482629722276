const routes = {
  path: '/admin/as',
  name: 'AdminAsMain',
  meta: {
    layout: 'AdmLayout',
    title: 'A/S 고객등록 및 관리',
    dep: 3
  },
  component: () => import(/* webpackChunkName: "adminas" */ '@/views/admin/afterservice/Main.vue'),
  children: [
    {
      path: 'list',
      alias: '',
      name: 'AdminAsList',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/admin/afterservice/List.vue'),
      meta: {
        btnOn: 1
      }
    },
    {
      path: 'write',
      alias: '',
      name: 'AdminAsWrite',
      component: () => import(/* webpackChunkName: "adminas" */ '@/views/admin/afterservice/Write.vue'),
      meta: {
        btnOn: 1
      }
    }
  ]
}

export default routes
