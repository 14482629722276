const routes = {
  path: '/member',
  name: 'MemberMain',
  meta: {
    layout: 'NoneLayout',
  },
  component: () => import(/* webpackChunkName: "member" */ '@/views/web/member/Main.vue'),
  children: [
    {
      path: 'login',
      alias: '',
      name: 'Login',
      component: () => import(/* webpackChunkName: "member" */ '@/views/web/member/Login.vue'),
      meta: {}
    },
    {
      path: 'findid',
      alias: '',
      name: 'FindId',
      component: () => import(/* webpackChunkName: "member" */ '@/views/web/member/FindId.vue'),
      meta: {}
    },
    {
      path: 'findpw',
      alias: '',
      name: 'FindPw',
      component: () => import(/* webpackChunkName: "member" */ '@/views/web/member/FindPw.vue'),
      meta: {}
    },
    {
      path: 'findpw2',
      alias: '',
      name: 'FindPw2',
      component: () => import(/* webpackChunkName: "member" */ '@/views/web/member/FindPw2.vue'),
      meta: {}
    },
    {
      path: 'join1',
      alias: '',
      name: 'Join1',
      component: () => import(/* webpackChunkName: "member" */ '@/views/web/member/Join1.vue'),
      meta: {}
    },
    {
      path: 'join2',
      alias: '',
      name: 'Join2',
      component: () => import(/* webpackChunkName: "member" */ '@/views/web/member/Join2.vue'),
      meta: {

      }
    }
  ]
}

export default routes
