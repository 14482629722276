<template>
  <div class="topwrap">
    <router-link :to="{name:'NoticeList'}" class="text14 mo" v-if="this.notiYn=='Y'"><span>new</span> 새로운공지사항이있습니다 <button type="button"><img src="/img/ico_elis.png"></button></router-link>
    <div class="top">
      <button type="button" @click="leftHidden" class="menu_btn"><img src="/img/ico_menu.png" /></button>
      <p class="title" v-if="title !== 'hidden'">{{ title }}</p>
      <div class="my">
        <router-link :to="{name:'NoticeList'}" class="text14 pc" v-if="this.notiYn=='Y'"><span>new</span> 새로운공지사항이있습니다 </router-link>
        <p class="text16">{{ userName }} 님</p>
        <button type="button" class="linebtn" @click="logout">로그아웃</button>
      </div>
    </div>
  </div>
</template>

<script>
import ax from '@/api/member'
export default {
  name: 'Subheader',
  data() {
    return {
      userName: '',
      notiYn : 'N'
    }
  },

  computed: {
    title() {
      if (this.userName == null) {
        let params = {}
        ax.getLoginInfo(params, (flag, data) => {
          if (flag) {
            if (data.data.role == 'ROLE_SUPER' || data.data.role == 'ROLE_ADMIN') {
              sessionStorage.setItem('SWUName', '관리자')
              localStorage.setItem('SWUName', '관리자')
              this.userName = '관리자'
              if (this.$router.currentRoute._value.fullPath.indexOf('/admin') == -1) {
                this.$router.push({
                  name: 'Apply'
                })
              }
            } else {

              sessionStorage.setItem('SWUName', data.data.companyName)
              localStorage.setItem('SWUName', data.data.companyName)
              localStorage.setItem('SWUSeq', data.data.seq)
              sessionStorage.setItem('SWUSeq', data.data.seq)
              this.userName = data.data.companyName
              this.userSeq = data.data.seq
            }
          } else {
            alert(data.message)
          }
        })
      }
      if (this.$route.name == 'CustomerWrite') {
        if (this.$route.query.processStatus == 'RECEIPT') {
          return '신규 고객 관리'
        } else if (this.$route.query.processStatus == 'CONTRACT') {
          return '계약건 관리'
        } else if (this.$route.query.processStatus == 'CONSTRUCTION') {
          return '완료공사 관리'
        }
      }
      return this.$route.meta.title || ''
    }
  },
  mounted() {
    this.userName = sessionStorage.getItem('SWUName')
    this.getLoginInfo();
  },
  methods: {
    leftHidden() {
      this.$emit('leftHidden')
    },
    logout() {
      sessionStorage.removeItem('eotoken')
      localStorage.removeItem('eotoken')
      this.$router.push({
        name: 'Login'
      })
    },
    getLoginInfo(){
      let params = {}
      ax.getLoginInfo(params, (flag, data) => {
        if (flag) {
          this.notiYn = data.data.notiYn

          if (data.data.role == 'ROLE_SUPER' || data.data.role == 'ROLE_ADMIN') {
            this.notiYn = 'N'

          }

        } else {
          alert(data.message)
        }
      })
    }
  },
  watch: {
    '$route.name': function () {
      this.getLoginInfo();
    }
  }
}
</script>
