const routes = {
  path: '/profitloss',
  name: 'ProfitLossMain',
  meta: {
    layout: 'SubLayout',
    dep: 5,
    title: '경영관리'
  },
  component: () => import(/* webpackChunkName: "profitloss" */ '@/views/web/profitloss/Main.vue'),
  children: [
    {
      path: 'b2cdivision',
      alias: '',
      name: 'B2cDivision',
      component: () => import(/* webpackChunkName: "profitloss" */ '@/views/web/profitloss/B2cDivision.vue'),
    },
    {
      path: 'b2cgraph',
      alias: '',
      name: 'B2cGraph',
      component: () => import(/* webpackChunkName: "profitloss" */ '@/views/web/profitloss/B2cGraph.vue'),
    },
    {
      path: 'b2cpl',
      alias: '',
      name: 'B2cPl',
      component: () => import(/* webpackChunkName: "profitloss" */ '@/views/web/profitloss/B2cPl.vue'),
    }
  ]
}

export default routes
