<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

