const routes = {
  path: '/admin/partner',
  name: 'AdminPartnerMain',
  meta: {
    layout: 'AdmLayout',
    title: '회원사 관리',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "adminpartner" */ '@/views/admin/partner/Main.vue'),
  children: [
    {
      path: 'apply',
      alias: '',
      name: 'Apply',
      component: () => import(/* webpackChunkName: "adminpartner" */ '@/views/admin/partner/Apply.vue'),
    },
    {
      path: 'partnerlist',
      name: 'PartnerList',
      component: () => import(/* webpackChunkName: "adminpartner" */ '@/views/admin/partner/PartnerList.vue'),
    },
    {
      path: 'currentlist',
      name: 'CurrentList',
      component: () => import(/* webpackChunkName: "adminpartner" */ '@/views/admin/partner/CurrentList.vue'),
    },
    {
      path: 'partnerinfo',
      name: 'PartnerInfo',
      component: () => import(/* webpackChunkName: "adminpartner" */ '@/views/admin/partner/PartnerInfo.vue'),
    },
    {
      path: 'admininfo',
      name: 'AdminInfo',
      component: () => import(/* webpackChunkName: "adminpartner" */ '@/views/admin/partner/AdminInfo.vue'),
    }

  ]
}

export default routes
