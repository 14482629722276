const routes = {
  path: '/admin/setting',
  name: 'AdminSettingMain',
  meta: {
    layout: 'AdmLayout',
    title: '설정',
    dep: 7
  },
  component: () => import(/* webpackChunkName: "setting" */ '@/views/admin/setting/Main.vue'),
  children: [
    {
      path: 'adminInfo',
      alias: '',
      name: 'AdminInfo',
      component: () => import(/* webpackChunkName: "setting" */ '@/views/admin/setting/AdminInfo.vue'),
    },
    {
      path: 'menusetting',
      alias: '',
      name: 'AdminMenuSetting',
      component: () => import(/* webpackChunkName: "setting" */ '@/views/admin/setting/MenuSetting.vue'),
    }
  ]
}

export default routes
