const routes = {
  path: '/admin/delete',
  name: 'AdminDeleteMain',
  meta: {
    layout: 'AdmLayout',
    dep: 6,
    title: '휴지통'
  },
  component: () => import( /* webpackChunkName: "admindelete" */ '@/views/admin/delete/Main.vue'),
  children: [
    {
      path: 'delete',
      alias: '',
      name: 'Delete',
      component: () => import( /* webpackChunkName: "admindelete" */ '@/views/admin/delete/Delete.vue'),
    }
  ]
}

export default routes