const routes = {
  path: '/admin/notice',
  name: 'AdminNoticeMain',
  meta: {
    layout: 'AdmLayout',
    title: '공지사항',
    dep: 8
  },
  component: () => import(/* webpackChunkName: "notice" */ '@/views/admin/notice/Main.vue'),
  children: [
    {
      path: 'list',
      name: 'AdminNoticeList',
      dep: 9,
      component: () => import(/* webpackChunkName: "notice" */ '@/views/admin/notice/NoticeList.vue'),
    },
    {
      path: 'write',
      name: 'AdminNoticeWrite',
      dep: 9,
      component: () => import(/* webpackChunkName: "notice" */ '@/views/admin/notice/NoticeWrite.vue'),
    }
  ]
}

export default routes
