<template>
  <div class="container-fluid">
    <Header />
    <slot />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'DefaultLayout',
  components: {
    Footer,
    Header
  },


}
</script>
