const routes = {
  path: '/admin/stats',
  name: 'AdminStatisticsMain',
  meta: {
    layout: 'AdmLayout',
    title: '통계',
    dep: 5
  },
  component: () => import(/* webpackChunkName: "adminstatistics" */ '@/views/admin/stats/Main.vue'),
  children: [
    {
      path: 'connect',
      alias: '',
      name: 'Connect',
      component: () => import(/* webpackChunkName: "adminstatistics" */ '@/views/admin/stats/Connect.vue'),
    },
    {
      path: 'estimate',
      alias: '',
      name: 'EstimateStats',
      component: () => import(/* webpackChunkName: "adminstatistics" */ '@/views/admin/stats/Estimate.vue'),
    },
    {
      path: 'superas',
      alias: '',
      name: 'SuperAs',
      component: () => import(/* webpackChunkName: "adminstatistics" */ '@/views/admin/stats/SuperAs.vue'),
      meta: {
        btnOn: 1
      }
    }
  ]
}

export default routes
