import axios from '@/api/axios'
import store from '@/store'
import _ from 'lodash'
import qs from 'qs'
import {mapKeysCamelCase, mapKeysSnakeCase} from '@/utils/deepKeysMap'

const checkId = (data, result) => {
  let finalData = {
    result_code: '200',
    message: ''
  }
  const options = {
    method: 'GET',
    url: '/member/checkId',
    params: data
  }

  try {
    axios(options).then((response) => {
      if (response.data.code == 200) {
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error('checkId', err)
    result(false, err)
  }
}
const regist = (data, result) => {
  let finalData = {
    result_code: '200',
    message: ''
  }
  const options = {
    method: 'POST',
    url: '/member',
    data : data
  }

  try {
    axios(options).then((response) => {
      if (response.data.code == 200) {
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error('regist', err)
    result(false, err)
  }
}
const findId = (data, result) => {
  let finalData = {
    result_code: '200',
    message: ''
  }
  const options = {
    method: 'GET',
    url: '/member/findId',
    params: data
  }

  try {
    axios(options).then((response) => {
      if (response.data.code == 200) {
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error('findId', err)
    result(false, err)
  }
}

const findPw = (data, result) => {
  let finalData = {
    result_code: '200',
    message: ''
  }
  const options = {
    method: 'GET',
    url: '/member/findPw',
    params: data
  }

  try {
    axios(options).then((response) => {
      if (response.data.code == 200) {
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error('findId', err)
    result(false, err)
  }
}

const getInfo = ( data, result) => {
  let finalData = {
    result_code: 200,
    message: ''
  }
  const options = {
    method: 'GET',
    url: '/member/info',
  }

  try {
    axios(options).then((response) => {
      if (response.data.code == 200) {
        //console.dir(response)
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error('getInfo', err)
    result(false, err)
  }
}
const updateMember = (data, result) => {
  let finalData = {
    result_code: '200',
    message: ''
  }
  const options = {
    method: 'PUT',
    url: '/member',
    data : data
  }

  try {
    axios(options).then((response) => {
      if (response.data.code == 200) {
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error('updateMember', err)
    result(false, err)
  }
}


const getLoginInfo = ( data, result) => {
  let finalData = {
    result_code: 200,
    message: ''
  }
  const options = {
    method: 'GET',
    url: '/member/loginInfo',
  }

  try {
    axios(options).then((response) => {
      if (response.data.code == 200) {
        //console.dir(response)
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error('getLoginInfo', err)
    result(false, err)
  }
}

const getMainInfo = ( data, result) => {
  let finalData = {
    result_code: 200,
    message: ''
  }
  const options = {
    method: 'GET',
    url: '/member/main',
  }

  try {
    axios(options).then((response) => {
      if (response.data.code == 200) {
        //console.dir(response)
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error('getMainInfo', err)
    result(false, err)
  }
}

const getMainStatsInfo = ( data, result) => {
  let finalData = {
    result_code: 200,
    message: ''
  }
  const options = {
    method: 'GET',
    url: '/member/main/stats',
    params: data
  }

  try {
    axios(options).then((response) => {
      if (response.data.code == 200) {
        //console.dir(response)
        finalData = _.merge(finalData, response.data)
        result(true, finalData)
      } else {
        //console.error(response.data.result_code, response.data.message)
        result(false, response.data)
      }
    })
  } catch (err) {
    console.error('getMainStatsInfo', err)
    result(false, err)
  }
}
export default {
  checkId,
  regist,
  findId,
  findPw,
  getInfo,
  getLoginInfo,
  getMainInfo,
  getMainStatsInfo,
  updateMember
}
