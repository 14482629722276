const routes = {
  path: '/notice',
  name: 'NoticeMain',
  meta: {
    layout: 'SubLayout',
    title: '공지사항',
    dep: 9
  },
  component: () => import(/* webpackChunkName: "notice" */ '@/views/web/notice/Main.vue'),
  children: [
    {
      path: 'noticelist',
      name: 'NoticeList',
      component: () => import(/* webpackChunkName: "notice" */ '@/views/web/notice/NoticeList.vue'),
    },
    {
      path: 'noticewrite',
      name: 'NoticeWrite',
      component: () => import(/* webpackChunkName: "notice" */ '@/views/admin/notice/NoticeWrite.vue'),
    }
  ]
}

export default routes
