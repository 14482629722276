<template>
  <div id="collapseExample" class="header1">
    <div class="sidebar-heading">
      <router-link to="/">
        <img src="/img/logo.png" />
      </router-link>
    </div>
    <div class="sidebar-body">
      <ul>
        <li>
          <router-link :to="{name: 'Main'}" :class="{on: page == 8}">
            <span>홈</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'CustomerList', query: {businessType: 'B2C'}}" :class="{on: page == 1}">
            <span>신규고객 관리</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'ContractList', query: {businessType: 'B2C'}}" :class="{on: page == 2}">
            <span>계약건 관리</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'DoneList', query: {businessType: 'B2C'}}" :class="{on: page == 3}">
            <span>완료공사 관리</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'AsList', query: {businessType: 'B2C', host: 'U'}}" :class="{on: page == 4}">
            <span>A/S 관리</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'B2cDivision', query: {businessType: 'B2C'}}" :class="{on: page == 5}">
            <span>경영관리</span>
          </router-link>
        </li>

        <li>
          <router-link :to="{name: 'DeleteList', query: {businessType: 'B2C'}}" :class="{on: page == 6}">
            <span>휴지통</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'MenuSetting'}" :class="{on: page == 7}">
            <span>설정</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'NoticeList'}" :class="{on: page == 9}">
            <span>공지사항</span>
          </router-link>
        </li>
      </ul>
      <div class="company-info">
        <p
          class="text14 dropdown-toggle collapsed"
          data-bs-toggle="collapse"
          href="#company"
          role="button"
          aria-expanded="false"
          aria-controls="company"
        >
          주식회사 슈퍼워런티
          <span></span>
        </p>
        <div class="collapse multi-collapse" id="company">
          <p class="text14">대표자 : 우준휘</p>
          <p class="text14">서울특별시 강남구 테헤란로2길 27, 1312호 (역삼동, 비젼타워)</p>
          <p class="text14">전화: 1670.0653</p>
          <p class="text14">이메일: superwarranty@naver.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
$("meta[property='og\\:name']").attr('content', 'width=device-width,initial-scale=1.0')
export default {
  name: 'Header',
  data() {
    return {
      page: this.$route.meta.dep
    }
  },
  created() {
    let accessToken = sessionStorage.getItem('eotoken') || localStorage.getItem('eotoken')

    if (accessToken == null) {
      this.$router.replace('/member/login')
      return
    }
  },
  methods: {
    open() {
      alert('전자계약서 관리기능은 곧 오픈 예정입니다.')
    }
  },
  watch: {
    '$route.name': function () {
      this.page = this.$route.meta.dep
      if (this.$route.name == 'CustomerWrite') {
        if (this.$route.query.processStatus == 'RECEIPT') {
          this.page = 1
        } else if (this.$route.query.processStatus == 'CONTRACT') {
          this.page = 2
        } else if (this.$route.query.processStatus == 'CONSTRUCTION') {
          this.page = 3
        }
      }
    }
  }
}
</script>
