<template>
  <div class="container-fluid">
    <slot/>
  </div>
</template>

<script>

export default {
  name: 'NoneLayout',
  mounted() {
    $("meta[name='viewport']").attr("content", 'initial-scale=1.0,user-scalable=no,maximum-scale=1,width=device-width' );
  },
}

</script>
