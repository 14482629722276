const routes = {
  path: '/admin/sales',
  name: 'AdminSalesMain',
  meta: {
    layout: 'AdmLayout',
    title: '매출관리',
    dep: 4
  },
  component: () => import(/* webpackChunkName: "adminsales" */ '@/views/admin/sales/Main.vue'),
  children: [
    {
      path: 'subscribe',
      alias: '',
      name: 'Subscribe',
      component: () => import(/* webpackChunkName: "adminsales" */ '@/views/admin/sales/Subscribe.vue'),
    },
    {
      path: 'afterservice',
      name: 'AfterServiceSales',
      component: () => import(/* webpackChunkName: "adminsales" */ '@/views/admin/sales/AfterService.vue'),
      meta: {
        btnOn: 1
      }
    },
    {
      path: 'afterserviceb2b',
      name: 'AfterServiceB2b',
      component: () => import(/* webpackChunkName: "adminsales" */ '@/views/admin/sales/AfterService.vue'),
      meta: {
        btnOn: 1
      }
    },
    {
      path: 'totalsales',
      name: 'TotalSales',
      component: () => import(/* webpackChunkName: "adminsales" */ '@/views/admin/sales/TotalSales.vue'),
    }
  ]
}

export default routes
