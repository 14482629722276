const routes = {
  path: '/setting',
  name: 'SettingMain',
  meta: {
    layout: 'SubLayout',
    title: '설정',
    dep: 7
  },
  component: () => import(/* webpackChunkName: "setting" */ '@/views/web/setting/Main.vue'),
  children: [
    {
      path: 'myinfo',
      alias: '',
      name: 'MyInfo',
      component: () => import(/* webpackChunkName: "setting" */ '@/views/web/setting/MyInfo.vue'),
    },
    {
      path: 'menusetting',
      alias: '',
      name: 'MenuSetting',
      component: () => import(/* webpackChunkName: "setting" */ '@/views/web/setting/MenuSetting.vue'),
    }
  ]
}

export default routes
