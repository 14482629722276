const routes = {
  path: '/customer',
  name: 'CustomerMain',
  meta: {
    layout: 'SubLayout',
    dep: 1
  },
  component: () => import(/* webpackChunkName: "customer" */ '@/views/web/customer/Main.vue'),
  children: [
    {
      path: 'list',
      alias: '',
      name: 'CustomerList',
      component: () => import(/* webpackChunkName: "customer" */ '@/views/web/customer/List.vue'),
      meta: {
        title: '신규고객 관리'
      }
    },
    {
      path: 'write',
      name: 'CustomerWrite',
      component: () => import(/* webpackChunkName: "customer" */ '@/views/web/customer/Write.vue'),
      meta: {
        title: '신규고객 관리'
      }
    },
    {
      path: 'contactList',
      alias: '',
      name: 'ContactList',
      component: () => import(/* webpackChunkName: "customer" */ '@/views/web/customer/ContactList.vue'),
      meta: {
        title: '금일 연락자 목록'

      }
    },
    {
      path: 'contractList',
      alias: '',
      name: 'ContractList',
      component: () => import(/* webpackChunkName: "customer" */ '@/views/web/customer/ContractList.vue'),
      meta: {
        title: '계약건 관리',
        dep :2
      }
    },
    {
      path: 'doneList',
      alias: '',
      name: 'DoneList',
      component: () => import(/* webpackChunkName: "customer" */ '@/views/web/customer/DoneList.vue'),
      meta: {
        title: '완료공사 관리',
        dep :3
      }
    },

    {
      path: 'deleteList',
      alias: '',
      name: 'DeleteList',
      component: () => import(/* webpackChunkName: "customer" */ '@/views/web/customer/DeleteList.vue'),
      meta: {
        title: '휴지통',
        dep :6
      }
    }
  ]
}

export default routes
