import {createRouter, createWebHistory} from 'vue-router'
import memberPath from './path.member'
import customerPath from './path.customer'
import afterservicePath from './path.afterservice'
import profitlossPath from './path.profitloss'
import settingPath from './path.setting'

import adminpartnerPath from './path.adminpartner'
import adminclientPath from './path.admincustomer'
import adminasPath from './path.adminas'
import adminsalesPath from './path.adminsales'
import adminstatisticsPath from './path.adminstatistics'
import admindeletePath from './path.admindelete'
import adminsettingPath from './path.adminsetting'
import adminnoticePath from './path.adminnotice'

import noticePath from './path.notice'

const routes = [
  {
    path: '/',
    alias: '',
    name: 'Main',
    component: () => import(/* webpackChunkName: "main" */ '@/views/Main.vue'),
    meta: {
      layout: 'SubLayout',
      title: '홈',
      dep: 8
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.addRoute(memberPath)
router.addRoute(customerPath)
router.addRoute(afterservicePath)
router.addRoute(profitlossPath)
router.addRoute(settingPath)

router.addRoute(adminpartnerPath)
router.addRoute(adminclientPath)
router.addRoute(adminasPath)
router.addRoute(adminsalesPath)
router.addRoute(adminstatisticsPath)
router.addRoute(admindeletePath)
router.addRoute(adminsettingPath)
router.addRoute(adminnoticePath)

router.addRoute(noticePath)

export default router
