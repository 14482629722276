const routes = {
  path: '/afterservice',
  name: 'AfterServiceMain',
  meta: {
    layout: 'SubLayout',
    title: 'A/S 관리',
    dep: 4
  },
  component: () => import(/* webpackChunkName: "afterservice" */ '@/views/web/afterservice/Main.vue'),
  children: [

    {
      path: 'write',
      name: 'AsWrite',
      component: () => import(/* webpackChunkName: "afterservice" */ '@/views/web/afterservice/Write.vue'),
    },
    {
      path: 'list',
      name: 'AsList',
      component: () => import(/* webpackChunkName: "afterservice" */ '@/views/web/afterservice/List.vue'),
    }
  ]
}

export default routes
